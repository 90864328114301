html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  color: $color__grey;
  font-family: $font-primary;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.app {
  @apply relative overflow-hidden flex flex-col;
  min-height: 100vh;
}

.error-page {
  position: relative;
  width: 100%;
  overflow: hidden;

  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 80vw;
    max-width: calc-rem(1330);
    height: 80vw;
    background: url('/assets/front/img/error-shape.svg') no-repeat;
    background-size: cover;
    transform: translateY(50%);
    content: '';
    pointer-events: none;


    @include responsive(xl) {
      width: 60vw;
      height: 60vw;
    }
  }
}

.app-body {
  @apply relative;
  z-index: 5;
  flex-grow: 1;
  padding-top: 4rem;
  
  &:after {
    position: fixed;
    inset: 0;
    z-index: 10;
    background-color: rgba($color__burgundy, .5);
    opacity: 0;
    transition: .4s ease-in-out opacity;
    content: '';
    pointer-events: none;
  }

  &.has-overlay {
    &:after {
      opacity: 1;
    }
  }

  @include responsive(md) {
    padding-top: calc-rem(112); // height header
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

.preload * {
  transition: none!important;
}

a, button {
  transition: .4s ease-in-out color;
}

svg {
  transition: .4s ease-in-out fill;
}