@keyframes drawBgLine {
  0% {
    background-size: 0% 2px;
  }

  100% {
    background-size: 100% 2px;
  }
}

@keyframes drawBgLine2 {
  0% {
    background-size: 0% 1px;
  }

  100% {
    background-size: 100% 1px;
  }
}