.button {
  @apply uppercase text-center inline-block;
  padding: .75rem 1.5rem;
  font-weight: bold;
  font-size: calc-rem(11);
  line-height: 1;
  letter-spacing: 1px;
  border: 2px solid;
  border-radius: 1.75rem;
  transition: .4s ease-in-out color, .4s ease-in-out border-color, .4s ease-in-out background-color; 

  @include responsive(md) {
    padding: 1rem 1.5rem;
    font-size: .75rem;
  }

  @each $name, $color in $button-colors {
    &--#{$name} {
      color: $color;
      border-color: $color;

      &:hover,
      a:hover & {
        color: $color__white;
        background-color: $color;
      }
    }

    &--full-#{$name} {
      color: $color__white;
      background-color: $color;
      border-color: $color;

      &:hover {
        color: $color;
        background-color: $color__white;
        border-color: $color__white;

        svg {
          fill: $color;
        }
      }
    }
  }

  &--white {
    &:hover,
    a:hover & {
      color: $color__dark-red;
    }
  }

  &--full-light-purple {
    &:hover {
      color: $color__purple;
      
      svg {
        fill: $color__purple;
      }
    }
  }

  &--full-purple {
    &.button--hover-border {
      &:hover {
        border-color: $color__purple2;
      }
    }
  }

  &--full-dark-red {
    &:hover {
      border-color: $color__dark-red;
    }
  }

  &--full-white {
    color: $color__purple;

    &:hover {
      color: $color__white;
      background-color: transparent;
      border-color: $color__white;
      cursor: pointer;
    }
  }

  &--full-white-red {
    color: $color__light-burgundy;

    &:hover {
      color: $color__white;
    }
  }

  &--icon {
    @apply inline-flex items-center justify-center;
    height: 2.5rem;
    padding-left: 1rem;

    svg {
      flex-shrink: 0;
      margin-right: .5rem;
      transition: .4s ease-in-out fill;

      @include max-responsive(md) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    @include responsive(md) {
      height: 3rem;
    }
  }

  &.is-disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.link {
  @apply uppercase inline-block;
  font-weight: bold;
  font-size: calc-rem(11);
  letter-spacing: 1px;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2px;

  &:hover,
  a:hover & {
    animation: .4s ease-in-out drawBgLine;
  }

  @include responsive(md) {
    font-size: .75rem;
  }
}