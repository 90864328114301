.sr-only {
  @include sr-only();
}

// avoid broken image symbol
img.lazyload:not([src]) {
  visibility: hidden;
}

.no-scroll-y {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.object-fit-image-wrap {
  @apply absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.object-fit-image {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  /* stylelint-disable-next-line */
  font-family: 'object-fit: cover;'; // Polyfill
}

.icon {
  @each $name, $color in $icon-colors {
    &--#{$name} {
      fill: $color;
    }
  }

  &--reversed {
    transform: scaleX(-1);
  }

  &--rotated {
    transform: rotate(-90deg);
  }
}

// use it with js-full-height-mobile
// useful for calculations w/ IOS bottom bar
.full-height-mobile {
  @include max-responsive(md) {
    min-height: 100vh; 
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.icon-centered {
  @apply flex items-center justify-center;
}

.shaped-image {
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url('/assets/front/img/losange-shape.svg');
  mask-image: url('/assets/front/img/losange-shape.svg');
}

.full-mobile {
  @include max-responsive(md) {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem;
  }
}

.full-right {
  @include responsive(md) {
    width: calc(100% + 2rem);
    margin-right: -2rem;
  }

  @include responsive(xl) {
    width: calc(100% + 120px);
    margin-right: calc-rem(-120);
  }
}

.full-left {
  @include responsive(md) {
    width: calc(100% + 2rem);
    margin-left: -2rem;
  }

  @include responsive(xl) {
    width: calc(100% + 120px);
    margin-left: calc-rem(-120);
  }
}

// to put on wrapper with overflow hidden + rounded lg with image scale in it with transition
.safari-wrap-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.img-transition {
  transition: .4s ease-in-out transform;

  a:hover & {
    transform: scale(1.025);
  }
}

.flex.hidden {
  display: none;
}
