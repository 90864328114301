.footer {
  padding: 1.5rem 0;
  background-color: $color__white;
  border-top: 1px solid $color__border;

  @include responsive(md) {
    padding: 2rem 0 2.5rem;
  }

  &__inner {
    @include responsive(xl) {
      margin: 0 -5rem;
    }
  }

  &__logo {
    @include max-responsive(md) {
      width: auto;
      height: calc-rem(56);
    }
  }

  &__link {
    font-weight: 600;

    &:hover {
      color: $color__dark-red;
    }
  }

  &__social {
    transition: .4s ease-in-out fill;
    fill: $color__white;

    @include max-responsive(md) {
      width: 2.5rem;
      height: 2.5rem;
    }

    &-inner {
      transition: .4s ease-in-out fill;
    }

    &:hover {
      .footer__social-inner {
        fill: $color__white;
      }
    }

    &--instagram:hover {
      fill: #FF3150;
    }

    &--youtube:hover {
      fill: #C4302B;
    }

    &--twitter:hover {
      fill: #1DA1F2;
    }

    &--linkedin:hover {
      fill: #0077B5;
    }

    &--facebook:hover {
      fill: #1877F2;
    }
  }
}