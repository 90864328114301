@use "sass:map";

$grid-columns: 12;

// MAX CONTAINER
$base-max-container: 100vw;
$responsive-max-containers: (
  md: 100vw,
  lg: 100vw,
  xl: 1600px,
);

// OUTTER GUTTERs
// outter-margin
$base-outter-margin: 1.5rem;
$responsive-outter-margins: (
  md: 2rem,
  lg: 2rem,
  xl: 120px,
);

// INNER GUTTER
$base-inner-gutter: 1rem;
$responsive-inner-gutters: (
  md: 1.5rem,
  lg: 1.5rem,
  xl: 1.5rem,
);

// List of keys, used in layout.
$breakpoints-keys: map.keys($breakpoints);
$responsive-max-containers-keys: map.keys($responsive-max-containers);