// RADIOS
.custom-radio {
  @apply relative w-full flex;
  
  &__label {
    @apply relative flex items-center w-full;
    height: 4rem;
    padding: 1rem 2.75rem 1rem .75rem;
    color: $color__light-burgundy;
    font-weight: 600;
    font-size: calc-rem(18);
    letter-spacing: -.5px;
    background-color: $color__white;
    border: 2px solid $color__white;
    border-radius: .5rem;
    cursor: pointer;
    transition: .4s background-color ease-in-out, .4s color ease-in-out;

    @include max-responsive(md) {
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    @include responsive(md) {
      height: calc-rem(128);
      padding: 2rem 5rem 2rem 2rem;
      font-size: 1.5rem;
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      content: '';
    }

    &:before {
      right: calc-rem(10);
      width: 1.25rem;
      height: 1.25rem;
      background-color: $color__white;
      border: 1px solid $color__light-grey;
      transition: .4s border-color ease-in-out;

      @include responsive(md) {
        right: 2rem;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid $color__light-grey;
      }
    }

    &:after {
      right: calc-rem(13);
      width: calc-rem(13);
      height: calc-rem(10);
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.145 5.509 9l5.59-8' stroke='%23900C3F' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      opacity: 0;
      transition: .4s opacity ease-in-out;

      @include responsive(md) {
        right: 2.25rem;
      }
    }

    .icon {
      transition: .4s opacity ease-in-out;
    }

    .icon-selected {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &--small {
      height: auto;
      padding: .5rem 2.5rem .5rem 1rem;
      font-size: 1rem;
      letter-spacing: normal;

      @include responsive(md) {
        padding: .75rem 3rem .75rem 1.5rem;
        font-size: calc-rem(18);

        &:before {
          right: 1rem;
        }

        &:after {
          right: 1.25rem;
        }
      }
    }

    &--big {
      display: block;
      height: auto;
      padding: 2rem;
      color: $color__grey;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.38;

      span {
        display: block;
      }

      p:not(:last-child) {
        margin-bottom: .75rem;
      }

      &:before {
        top: 1.5rem;
        right: 1.5rem;
        transform: none;
      }

      &:after {
        top: 2.25rem;
        right: calc-rem(29);
      }
    }
  }

  &__title {
    margin-bottom: .75rem;
    padding-right: 2rem;
    color: $color__dark-red;
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: -.4px;
    transition: .4s ease-in-out color;
  }

  input[type="radio"] {
    @include sr-only;

    width: 100%;
    height: 100%;
  }

  input:checked + &__label {
    color: $color__white;
    background-color: $color__burgundy;

    &:before {
      border-color: $color__white;
    }

    &:after {
      opacity: 1;
    }

    .icon-selected {
      opacity: 1;
    }

    .icon-reg {
      opacity: 0;
    }

    .custom-radio__title {
      color: $color__white;
    }
  }
}