@use "sass:color";

$color__white: #fff;
$color__black: #000;
$color__grey: #686868;
$color__lighter-grey: #f4f4f4;
$color__light-grey: #b1a2a2;
$color__red: #ff3150;
$color__light-red: #ffeaed;
$color__light-red2: #ff9ba9;
$color__purple: #8d5eff;
$color__purple2:#613db8;
$color__light-purple: #9f77ff;
$color__light-purple2: #e2daf9;
$color__dark-purple: #241549;
$color__dark-red: #c70039;
$color__burgundy: #4d001e;
$color__light-burgundy: #900c3f;
$color__border: #d8d8d8;

$icon-colors: ( 
  'white': $color__white,
  'black': $color__black,
  'burgundy': $color__burgundy,
  'red': $color__red,
  'dark-red': $color__dark-red,
  'light-grey': $color__light-grey,
  'purple': $color__purple2
);

$button-colors: (
  'white': $color__white,
  'burgundy': $color__burgundy,
  'dark-red': $color__dark-red,
  'light-purple': $color__light-purple,
  'purple': $color__purple2,
)