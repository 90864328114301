.needs-modal {
  @apply relative;
  z-index: 1;
  background-color: $color__dark-red;

  &__intro {
    max-width: calc-rem(700);
    margin: 0 auto;
  }

  &__deco {
    @apply absolute;
    z-index: -1;
    width: 50%;
    max-width: calc-rem(720);

    &--top {
      top: 0;
      left: 0;
    }

    &--bottom {
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
    }
  }

  &--slice {
    padding: 4rem 1.5rem 5rem;
    overflow: hidden;

    @include responsive(md) {
      padding: 4rem 3rem 5rem;
      border-radius: .5rem;

      .needs-modal__deco {
        &--top {
          top: -20%;
        }
  
        &--bottom {
          bottom: -80%;
        }
      }
    }

    @include responsive(xl) {
      padding: 4rem calc-rem(76) 5rem;
    }
  }

  &__error {
    margin-top: 1rem;
    padding: .25rem .5rem;
    font-size: calc-rem(14);
    background-color: $color__red;

    &--end {
      @apply absolute;
      top: -3.75rem;
      left: 0;
    }
  }

  &__slider {
    .swiper-slide {
      opacity: .4;
      transition: .4s ease-in-out opacity;
    }

    .swiper-slide-active {
      opacity: 1;
    }

    @include responsive(md) {
      .swiper-slide-next {
        opacity: 1;
      }
    }

    @include responsive(lg) {
      .swiper-slide-next + .swiper-slide {
        opacity: 1;
      }
    }

    .swiper-button-disabled {
      opacity: 0;
    }

    .slider__button {
      @apply absolute;
      top: 50%;
      right: calc-rem(-72);
      z-index: 5;
      transform: translateY(-50%);

      @include max-responsive(xl) {
        display: none;
      }

      &--prev {
        right: auto;
        left: calc-rem(-72);
        transform: translateY(-50%) scaleX(-1);
      }
    }

    .scrollbar {
      height: 5px;
      margin-top: 2.5rem;
      background-color: $color__light-burgundy;
      border-radius: 3px;
    }

    .swiper-scrollbar-drag {
      height: 5px;
      background-color: $color__white;
      border-radius: 3px;
      cursor: grab;
    }
  }

  &__panel {
    @apply absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    transition: .4s ease-in-out opacity, .4s ease-in-out visibility, .4s ease-in-out transform;

    &.is-active {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    &.is-above {
      z-index: 10;
    }

    &.is-out {
      transform: translateX(-100%);
    }

    &--step2 {
      overflow: auto;
    }
  }

  &__panel-inner {
    @apply flex flex-col justify-between items-end pt-7;

    @include responsive(md) {
      @apply pt-15;
    }
  }

  &__back {
    @apply flex items-center absolute;
    top: calc-rem(36);
    left: 3rem;
    z-index: 10;
    color: $color__white;
    font-weight: 600;
    letter-spacing: -.5px;
    transition: .4s opacity ease-in-out;

    &.is-hidden {
      opacity: 0;
      pointer-events: none;
    }

    svg {
      transform: scaleX(-1);
    }

    @include max-responsive(md) {
      top: 1rem;
      left: 1rem;
      font-size: calc-rem(14);
    }

    &:hover {
      opacity: .6;
    }
  }

  &__end-button {
    font-weight: 700;
    font-size: .75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 2px solid;
    transition: .4s ease-in-out opacity;

    &:hover {
      opacity: .6;
    }
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: calc-rem(6);
    background-color: $color__light-burgundy;

    &-inner {
      width: 100%;
      height: calc-rem(6);
      background-color: $color__white;
      transform: scaleX(0);
      transform-origin: 0% 0%;
      transition: .4s ease-in-out transform;
    }
  }

  &__logo {
    @include max-responsive(md) {
      width: 4rem;
      height: auto;
    }
  }
}

.text-input {
  &__label {
    display: block;
    font-weight: 700;
    font-size: calc-rem(11);
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__input {
    width: 100%;
    padding: .5rem 0 calc-rem(10);
    letter-spacing: -.5px;
    background-color: transparent;
    border-bottom: 1px solid rgba($color__white, .5);

    @include responsive(md) {
      font-size: calc-rem(18);
    }

    &:focus {
      outline: none;
    }
  }
}

.loader {
  @apply absolute flex justify-center items-center;
  inset: 0;
  background-color: rgba($color__red, .4);

  &:after {
    display: block;
    width: 5rem;
    height: 5rem;
    margin: .5rem;
    border: .25rem solid $color__white;
    border-color: $color__white transparent;
    border-radius: 50%;
    animation: loader 1.2s linear infinite;
    content: '';
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}
