.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  height: 4rem;
  padding: calc-rem(10) 0;
  background-color: $color__white;
  transition: .4s ease-in-out background-color;
  
  &.is-fixed {
    position: fixed;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
  }

  &.is-visible {
    transform: translateY(0);
  }

  &.has-transition {
    transition: .4s ease-in-out transform;
  }

  @include responsive(md) {
    height: calc-rem(112);
    padding: 1rem 0;
  }

  &__logo {
    fill: $color__dark-red;

    @media screen and (min-width: 1280px) and (max-width: 1380px) {
      width: calc-rem(200);
    }

    @include max-responsive(md) {
      width: auto;
      height: calc-rem(45);
    }
  }

  &__subtitle {
    @apply inline-block;
    margin-bottom: 1.5rem;
    color: $color__red;
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: -.5px;
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 0% 2px;
    transition: .4s ease-in-out background-size;

    &--about {
      margin-bottom: .75rem;
    }

    &:hover {
      background-size: 100% 2px;
    }
  }

  &__inner {
    @include responsive(xl) {
      margin: 0 -5.5rem 0 -6rem;
    }
  }

  &__panel {
    @apply absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    padding: 1rem 0 2rem;
    background-color: $color__white;

    &--about {
      @include responsive(xl) {
        z-index: -1;
      }
    }

    &-inner {
      @apply overflow-auto;
      height: 100%;

      @include responsive(xl) {
        max-height: calc(100vh - 9rem);
      }
    }

    @include responsive(md) {
      padding: 1.5rem 0 3rem;
    }

    @include max-responsive(xl) {
      transform: translateX(100%);
      transition: .4s ease-in-out transform;

      &.is-visible {
        transform: translateX(0);
      }
    }

    @include responsive(xl) {
      top: calc-rem(112);
      bottom: auto;
      visibility: hidden;
      opacity: 0;
      transition: .4s ease-in-out opacity, .4s ease-in-out visibility;

      &.is-visible {
        visibility: visible;
        opacity: 1;
      }
    }

    &:before {
      @apply absolute;
      inset: 0;
      z-index: -1;
      background-color: $color__white;
      content: '';
    }

    &--about {
      &:before {
        background: url('/assets/front/img/menu-shape.svg') no-repeat;
        background-position: right -216px bottom -308px;
        background-size: 600px;
        pointer-events: none;

        @include responsive(md) {
          background-position: right -316px bottom -408px;
          background-size: 800px;
        }

        @include responsive(lg) {
          background-position: right -400px bottom -690px;
          background-size: 1200px;
        }

        @include responsive(xl) {
          top: calc-rem(-112);
          background-position: calc(50% + 420px) 26%;
          background-size: calc-rem(1330);
        }
      }
    }
  }

  &__panel-mobile {
    @include max-responsive(xl) {
      @apply fixed flex flex-col justify-between items-start overflow-hidden;
      top: calc-rem(112);
      right: 0;
      left: 0;
      height: calc(100vh - 112px);
      height: calc(var(--vh, 1vh) * 100 - 112px);
      padding: 2rem 1.5rem;
      background-color: $color__white;
      transform: translateX(100%);
      transition: .4s ease-in-out transform;

      &.is-visible {
        transform: translateX(0);
      }
    }

    @include max-responsive(md) {
      top: 4rem;
      height: calc(100vh - 4rem);
      height: calc(var(--vh, 1vh) * 100 - 4rem);
    }
  }
  
  &__city-square {
    flex-shrink: 0;
    width: calc-rem(56);
    height: calc-rem(56);
    margin-right: 1rem;
    background-color: $color__red;
    border-radius: 6px;

    @include max-responsive(md) {
      svg {
        width: auto;
        height: 2rem;
      }
    }

    @include responsive(md) {
      width: calc-rem(78);
      height: calc-rem(78);
      margin-right: 1.5rem;
    }
  }

  &__city-title {
    align-self: center;
    color: $color__black;
    font-weight: 600;
    font-size: calc-rem(18);
    line-height: 1.2;
    letter-spacing: -.5px;

    @include responsive(md) {
      font-size: 1.25rem;
      line-height: 1.3;
    }

    span {
      display: block;
      color: $color__red;
    }
  }

  &__solution {
    position: relative;
    flex-shrink: 0;
    width: 5rem;
    height: 5rem;
    margin-right: .5rem;
    border-radius: 6px;

    @include responsive(md) {
      width: calc-rem(102);
      height: calc-rem(102);
      margin-right: 1rem;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      height: 90%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }
    
    &--tools {
      background-color: $color__lighter-grey;
    }

    &--services {
      border: 1px solid #DEDEDE;
    }
  }

  &__cta {
    @apply xl:ml-10 flex-shrink-0;

    @include max-responsive(lg) {
      width: 100%;
      margin-top: 1.5rem;
    }

    @include responsive-between(lg, xl) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    @media screen and (min-width: 1280px) and (max-width: 1380px) {
      margin-left: 1.5rem;
    }
  }

  &__burger {
    .icon-close {
      display: none;
    }

    &.is-active {
      .icon-close {
        display: block;
      }

      .icon-open {
        display: none;
      }
    }

    svg {
      transition: .4s ease-in-out fill;
    }

    @include responsive(xl) {
      display: none;
    }
  }

  &__back {
    @apply flex items-center mb-4;
    color: $color__burgundy;
    font-weight: 600;
    font-size: calc-rem(15);
    letter-spacing: -.5px;

    svg {
      margin-right: .5rem;
      transform: scaleX(-1);
    }

    @include responsive(xl) {
      display: none;
    }
  }

  &__deco-about {
    @apply absolute;
    top: 0; right: 0;
  }

  &--white {
    &:not(.is-fixed):not(.is-open) {
      background-color: transparent;

      .header {
        &__logo {
          fill: $color__white;
        }

        &__burger {
          svg {
            fill: $color__white;
          }
        }

        &__cta {
          color: $color__dark-red;
          background-color: $color__white;
          border-color: $color__white;

          &:hover {
            color: $color__white;
            background-color: transparent;
          }
        }
      }
      
      @include responsive(xl) {
        .main-nav__link {
          color: $color__white;

          &:hover {
            color: rgba($color__white, .5);
          }
        }
      }
    }
  }
}