/* stylelint-disable */
@tailwind utilities;
@tailwind base;

@import 'tools';

// BASE
@import 'base/typography';
@import 'base/core';
@import 'base/common';
@import 'utils/resets';

// GRID
@import 'grid/config';
@import 'grid/layout';

// SHARED COMPONENTS
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/main-nav';
@import 'components/contact-block';
@import 'components/sticky-contact';
@import 'components/breadcrumb';
@import 'components/modals';
@import 'components/needs-modal';
@import 'components/slider';
@import 'components/form/custom-radio';
@import 'components/form/custom-checkbox';

/* stylelint-enable */ 