.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  pointer-events: none;
}

.modal {
  @apply absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: opacity .4s ease-in-out;
  pointer-events: all;

  &__inner {
    height: calc(100vh - 2.5rem);
    height: calc(var(--vh, 1vh) * 100 - 2.5rem);
    margin-top: 2.5rem;
    overflow: auto;

    @include responsive(md) {
      height: calc(100vh - 3.5rem);
      height: calc(var(--vh, 1vh) * 100 - 3.5rem);
      margin-top: 3.5rem;
    }

    &--step2 {
      height: auto;
      min-height: calc(100vh - 2.5rem);
      min-height: calc(var(--vh, 1vh) * 100 - 2.5rem);
      overflow: hidden;

      @include responsive(md) {
        min-height: calc(100vh - 3.5rem);
        min-height: calc(var(--vh, 1vh) * 100 - 3.5rem);
      }
    }
  }

  &__close {
    @apply flex items-center text-white;
    position: absolute;
    top: calc-rem(36);
    right: 3rem;
    z-index: 10;
    font-weight: 600;
    letter-spacing: -.5px;
    transition: .4s ease-in-out opacity;

    @include max-responsive(md) {
      top: 1rem;
      right: 1rem;
      font-size: calc-rem(14);

      svg {
        width: calc-rem(13);
        height: calc-rem(13);
      }
    }

    &:hover {
      opacity: .6;
    }
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}