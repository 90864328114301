// CHECKBOXS
.custom-checkbox {
  @apply relative flex;

  @include max-responsive(lg) {
    width: 100%;
  }
  
  &__label {
    @apply relative w-full;
    padding: calc-rem(10) 1rem calc-rem(10) 2.75rem;
    color: $color__light-burgundy;
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: -.5px;
    background-color: $color__white;
    border: 2px solid $color__white;
    border-radius: 1.75rem;
    cursor: pointer;
    transition: .4s background-color ease-in-out, .4s color ease-in-out;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      content: '';
    }

    &:before {
      left: .75rem;
      width: 1.25rem;
      height: 1.25rem;
      background-color: $color__white;
      border: 1px solid $color__light-grey;
      transition: .4s border-color ease-in-out;
    }

    &:after {
      left: 1rem;
      width: calc-rem(13);
      height: calc-rem(10);
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.145 5.509 9l5.59-8' stroke='%23900C3F' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      opacity: 0;
      transition: .4s opacity ease-in-out;
    }

    @include max-responsive(md) {
      @apply flex items-center;
      min-height: calc-rem(60);
    }

    @include responsive(md) {
      padding: 1rem 2rem 1rem 3.5rem;
      font-size: calc-rem(18);

      &:before {
        left: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid $color__light-grey;
      }

      &:after {
        left: calc-rem(22);
      }
    }
  }

  input {
    @include sr-only;

    width: 100%;
    height: 100%;
  }

  input:checked + &__label {
    color: $color__white;
    background-color: $color__burgundy;

    &:before {
      border-color: $color__white;
    }

    &:after {
      opacity: 1;
    }
  }
}