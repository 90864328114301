$font-primary: 'Poppins', sans-serif;

// titles
.title {
  font-weight: 600;
  line-height: 1;
  letter-spacing: -1px;

  &--XXS {
    font-size: 1.25rem;
    letter-spacing: -.75px;

    @include responsive(md) {
      font-size: 1.5rem;
    }
  }

  &--XS {
    font-size: calc-rem(22);
    line-height: 1.14;
    letter-spacing: -.88px;

    @include responsive(md) {
      font-size: 1.75rem;
    }
  }

  &--S {
    font-size: 1.5rem;
    line-height: 1.25;

    @include responsive(md) {
      font-size: 2rem;
    }
  }

  &--M {
    color: $color__burgundy;
    font-size: 1.75rem;

    @include max-responsive(md) {
      line-height: 1.14;
    }

    @include responsive(md) {
      font-size: 2.5rem;
    }
  }

  &--L {
    color: $color__burgundy;
    font-size: 1.75rem;

    @include responsive(md) {
      font-size: 3rem;
    }
  }

  &--XL {
    color: $color__burgundy;
    font-size: 2em;

    span {
      display: block;
      font-weight: 400;
      font-size: 1.5rem;
      letter-spacing: -1px;
    }

    @include max-responsive(md) {
      line-height: 1.2;
    }

    @include responsive(md) {
      font-size: 3.5rem;
      letter-spacing: -1.75px;

      span {
        font-size: 2rem;
      }
    }
  }

  &--XXL {
    font-size: calc-rem(36);

    @include responsive(md) {
      font-size: 4rem;
      letter-spacing: -2px;
    }
  }

  &--white {
    color: $color__white;
  }

  &--red {
    color: $color__red;
  }

  &--dark-red {
    color: $color__dark-red;
  }
}

// paragraph
.paragraph {
  font-size: calc-rem(15);
  line-height: 1.6;
    
  @include responsive(md) {
    font-size: calc-rem(18);
    line-height: 1.78;
  }
  
  &--XL {
    font-size: calc-rem(18);
    line-height: 1.2;
    letter-spacing: -.6px;

    @include responsive(md) {
      font-size: 1.25rem;
    }
  }

  p,ul,ol {
    &:not(:last-child) {
      margin-bottom: 1rem;

      @include responsive(md) {
        margin-bottom: 1.5rem;
      }
    }
  }

  li {
    @apply relative;
    padding-left: 1.5rem;

    &:before {
      position: absolute;
      content: '';
    }
  }

  ol {
    padding-left: 0;
    counter-reset: list;

    li {
      counter-increment: list;  

      &:before {
        left: 0;
        color: $color__dark-red;
        content: counters(list,".") ".";  
      }
    }
  }

  ul {
    li {
      &:before {
        top: calc-rem(13);
        left: 0;
        width: .5rem;
        height: .5rem;
        background-color: $color__dark-red;
        border-radius: 50%;
      }
    }
  }

  a,
  .button-link {
    color: $color__dark-red;
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 100% 1px;

    &:hover {
      animation: .4s ease-in-out drawBgLine2;
    }
  }
}

// suptitle
.suptitle {
  @apply uppercase;
  color: $color__purple2;
  font-weight: 500;
  font-size: .75rem;
  letter-spacing: 1.2px;

  @include responsive(md) {
    font-size: calc-rem(14);
    letter-spacing: 1.8px;
  }
}

// bullets
.bullets {
  li {
    @apply relative;
    padding-left: 2.25rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:before,
    &:after {
      @apply absolute;
      content: '';
    }

    &:before {
      top: 0;
      left: 0;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
    }

    &:after {
      top: calc-rem(5);
      left: calc-rem(5);
      width: calc-rem(11);
      height: calc-rem(11);
      background-size: cover;
    }

    @include responsive(md) {
      padding-left: 2.75rem;

      &:before {
        width: 1.5rem;
        height: 1.5rem;
      }

      &:after {
        top: calc-rem(6);
        left: calc-rem(6);
        width: .75rem;
        height: .75rem;
      }
    }
  }

  &--purple {
    li {
      &:before {
        background-color: $color__light-purple2;
        border: 1px solid $color__white;
      }

      &:after {
        background-image: url('/assets/front/img/check-purple.svg');
      }
    }
  }

  &--red {
    li {
      &:before {
        background-color: $color__white;
        border: 1px solid $color__light-red2;
      }

      &:after {
        background-image: url('/assets/front/img/check-red.svg');
      }
    }
  }
}

// info uppercase
.infos {
  @apply uppercase inline-block;
  font-weight: bold;
  font-size: .75rem;
  letter-spacing: 1px;
}