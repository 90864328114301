.sticky-contact {
  @apply flex items-center text-white overflow-hidden;
  position: fixed;
  right: 1rem;
  bottom: 3rem;
  z-index: 5;
  max-width: 4rem;
  height: 4rem;
  padding: .75rem .75rem .75rem calc-rem(14);
  letter-spacing: -.5px;
  white-space: nowrap;
  background-color: $color__purple;
  border-radius: 2.5rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  transition: .5s ease-in-out max-width;

  @include responsive(md) {
    right: 2rem;
  }

  &__button {
    width: 3rem;
    height: 2.5rem;
    background-color: $color__light-purple;
    border-radius: 1.25rem;
    transition: .4s ease-in-out background-color;

    &:hover {
      background-color: $color__white;

      svg {
        fill: $color__light-purple;
      }
    }
  }

  &__toggle {
    margin: -1rem calc-rem(-14);
    padding: 1rem calc-rem(14);
  }

  &.is-open {
    max-width: var(--widthStickyOpen);
  }

  &.is-init-open {
    @include responsive(md) {
      max-width: calc-rem(400);
    }
  }
}