.main-nav {
  @include max-responsive(md) {
    overflow: auto;
  }

  >li:not(:last-child) {
    @include responsive(xl) {
      margin-right: 1.5rem;
    }

    @include max-responsive(xl) {
      margin-bottom: 2rem;
    }

    @media screen and (min-width: 1280px) and (max-width: 1380px) {
      margin-right: 1.25rem;
    }
  }

  &__link {
    @apply relative;
    color: $color__burgundy;
    font-weight: 600;
    letter-spacing: -.5px;

    svg {
      pointer-events: none;
    }

    @include max-responsive(xl) {
      @apply flex w-full items-center justify-between;
      font-size: 1.25rem;
    }

    &:after {
      @apply absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid currentColor;
      transform: scaleX(0);
      transform-origin: 0% 0%;
      transition: .4s ease-in-out transform;
      content: '';
    }

    @include responsive(xl) {
      &:hover,
      &.is-active {
        color: $color__dark-red;
      }

      &.is-active:after {
        transform: scaleX(1);
      }

      svg {
        display: none;
      }
    }
  }
}

.subnav {
  li {
    min-height: 2rem;
  }

  li:not(:last-child) {
    margin-bottom: .5rem;
  }

  &__services {
    width: 100%;

    @include responsive(xl) {
      width: calc(50% - 1.25rem);
    }
  }

  &__link {
    @apply relative inline-block;
    padding-left: 1.5rem;
    transition: .4s ease-in-out padding;

    &:before {
      @apply absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('/assets/front/img/arrow-nav.svg');
      content: '';
    }

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-repeat: no-repeat;
      background-position: 0 100%;
      background-size: 0% 2px;
      transition: .4s ease-in-out background-size;
    }

    @include max-responsive(md) {
      font-size: calc-rem(15);
    }

    @include responsive(lg) {
      padding-left: calc-rem(26);

      &:after {
        @apply absolute;
        top: calc-rem(-3);
        left: calc-rem(-5);
        z-index: -1;
        width: 2rem;
        height: 2rem;
        background-color: $color__light-red;
        border-radius: 50%;
        opacity: 0;
        transition: .4s ease-in-out opacity;
        content: '';
      }

      &:hover {
        padding-left: 2.5rem;
        color: $color__dark-red;

        &:after {
          opacity: 1;
        }

        span {
          background-size: 100% 2px;
        }
      }
    }
  }
}