.slider {
  &__button {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid $color__burgundy;
    border-radius: 50%;
    transition: .4s ease-in-out background-color;

    @include responsive(md) {
      width: 3rem;
      height: 3rem;
    }

    @include max-responsive(md) {
      svg {
        width: calc-rem(8);
        height: auto;
      }
    }

    &:hover {
      background-color: $color__burgundy;

      svg {
        fill: $color__white;
      }
    }

    &--prev {
      transform: scaleX(-1);
    }

    &--white {
      border-color: $color__white;

      &:hover {
        background-color: $color__white;

        svg {
          fill: $color__burgundy;
        }
      }
    }
  }

  &__nav {
    position: relative;
    top: -.25rem;

    @include responsive(md) {
      top: -.5rem;
    }
  }
}

.swiper-slide {
  display: flex;
  height: auto!important;
}

.is-centered .swiper-wrapper {
  justify-content: center;
}

.swiper-button-disabled {
  border-color: $color__black;
  opacity: .2;
  pointer-events: none;
}

.swiper-button-lock {
  display: none;
}

.slider-cards {
  .swiper-slide {
    transition: .4s ease-in-out opacity;

    @include responsive-between(md, xl) {
      &:not(.swiper-slide-active) {
        opacity: .2;
      }
    }

    @include responsive(xl) {
      &:not(.swiper-slide-active):not(.swiper-slide-next) {
        opacity: .2;
      }
    }
  }

  @include max-responsive(md) {
    @apply relative;
    padding-bottom: calc-rem(76);

    .slider__nav {
      @apply absolute;
      top: auto;
      right: 0;
      bottom: 0;
    }

    &__cta {
      @apply absolute;
      bottom: 0;
      left: 0;
      max-width: calc(100% - 92px);
    }
  }

  &__left {
    @include responsive(md) {
      @apply relative z-2;

      &:before {
        @apply absolute;
        top: -.5rem;
        right: -1.5rem;
        bottom: -.5rem;
        left: -50vw;
        z-index: -1;
        background-color: $color__white;
        content: '';
      }
    }
  }
}