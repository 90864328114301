.contact-block {
  @apply relative overflow-hidden;
  color: $color__white;
  background-color: $color__purple;

  @include max-responsive(lg) {
    @apply text-center;

    .button {
      width: calc-rem(270);
      max-width: 100%;
    }
  }

  &__title {
    span {
      @apply relative inline-block;
      color: $color__dark-purple;
      
      &:before {
        @apply absolute;
        inset: 0;
        bottom: calc-rem(-10);
        background: url('/assets/front/img/contact-underline.svg') no-repeat;
        background-position: bottom 0 left 0;
        content: '';
      }
    }

    @include responsive-between(md,lg) {
      padding-right: 1.25rem;
    }
  }

  &__inner {
    padding: 3rem 0;

    @include responsive(md) {
      padding: calc-rem(84) 0;
    }

    @include responsive(lg) {
      width: calc-rem(740);
      margin: 0 auto;
    }
  }

  &__deco {
    @apply absolute;
    opacity: .35;
    pointer-events: none;
    
    &--1 {
      top: -1.5rem;
      left: -4.5rem;
      width: calc-rem(130);
      height: calc-rem(100);
      transform: rotate(-30deg);

      @include responsive(md) {
        top: calc-rem(-75);
        left: calc-rem(-200);
        width: calc-rem(390);
        height: calc-rem(326);
      }

      @include responsive(xl) {
        left: calc-rem(-284);
        width: calc-rem(460);
        height: calc-rem(385);
      }
    }

    &--2 {
      bottom: -2.75rem;
      left: 50%;
      width: calc-rem(106);
      height: calc-rem(82);
      transform: translateX(-50%) rotate(-15deg);
    }

    &--3 {
      top: 7rem;
      right: -2rem;
      width: calc-rem(60);
      height: calc-rem(52);
      transform: scaleX(-1) rotate(-30deg);

      @include responsive(md) {
        top: calc-rem(84);
        right: 1rem;
        width: calc-rem(102);
        height: calc-rem(90);
      }
    }

    &--4 {
      right: -4rem;
      bottom: -3rem;
      width: calc-rem(102);
      height: calc-rem(90);
      transform: scaleX(-1) rotate(-30deg);

      @include responsive(xl) {
        right: -9rem;
      }

      @include max-responsive(md) {
        display: none;
      }
    }
  }
}