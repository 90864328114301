.breadcrumb {
  margin-top: 1rem;
  color: $color__light-grey;

  &--white {
    color: rgba($color__white, .6);

    .breadcrumb__item {
      a:hover {
        color: $color__white;
      }
    }
  }

  @include responsive(md) {
    margin-top: 1.25rem;
  }

  &__item {
    position: relative;
    display: inline;
    font-size: .75rem;
    line-height: 1;
    letter-spacing: -.44px;

    @include responsive(md) {
      font-size: calc-rem(14);
    }

    a {
      background-image: linear-gradient(currentColor, currentColor);
      background-repeat: no-repeat;
      background-position: 0% 90%;
      background-size: 100% 1px;
      transition: .4s ease-in-out color;

      &:hover {
        color: $color__grey;
      }
    }
  }
}